$cloudmed-purple: #624B78;
$cloudmed-sky-blue: #6BDAD5;
$cloudmed-blue: #12BDC6;
$cloudmed-more-blue: #17a2b8;
$cloudmed-teal: #008C95;
$cloudmed-dark-blue: #2D4F82;
$cloudmed-charcoal: #334257;
$cloudmed-dark-charcoal: #333;
$cloudmed-arsenic: #3F4443;
$cloudmed-gray: #768692;
$cloudmed-lt-gray: #D9D9D6;
$cloudmed-bright-gray: #EFEFEF;
$cloudmed-dark-gray: #DEE2E6;
$cloudmed-orange: #FF8300;
$cloudmed-vermilion: #D83F16;
$cloudmed-alert-danger: #FF0049;

$r1-black: #081626;
$r1-blue: #0047BB;
$r1-sky-blue: #1CB2FF;
$r1-deep-blue: #110080;
$r1-celeste-blue: #63E1FF;
$r1-red: #CC3D49;
$r1-gray: #737880;
$r1-green: #00A690;
$r1-gray-background: #F6F8FA;

$primary: #FF8300;
$info: $r1-blue;
$secondary: $cloudmed-gray;
$danger: $cloudmed-alert-danger;

$link-color: $r1-blue;
$link-hover-color: $r1-deep-blue;
$pagination-blue-link: $r1-blue;

$component-active-bg: $r1-blue;

$card-title-color: $r1-blue;

/*
  TODO: translate into cloudmed values
  These are left over values from par8o
  branding
*/
$gray-dark: #222;
$gray-medium-dark: #838383;
$gray-medium: #ccc;
$gray: #ebebeb;
$gray-light: #f7f7f7;
$teal: #3f6f81;

$valid-color: #155724;
$valid-bg-color: #d4edda;
$valid-border-color: #c3e6cb;

$field-border-color: #ced4da;
$invalid-field-border-color: #dc3545;

// FIXME: having issues with esbuild importing bootstrap vars
$success: green !default;
$gray-400: #ced4da !default;
